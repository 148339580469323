<template>

    <div class="container mt-5">

        <h2>
            <i class="bi bi-person-lines-fill"></i>
            Liste des clients
        </h2>

        <div class="my-3">

            <button
                type="button"
                tag="button"
                class="btn btn-outline-primary btn-sm mx-3"
                @click.prevent="createResource()"
              >
                <i class="bi bi-pencil-square"></i>
                New customer
            </button>

        </div>

        <div class="my-3">
            <div class="form-check form-check-inline">
                <label class="form-check-label" for="filters">Filters on :</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="useCustomers.state.checkedCustomers" class="form-check-input" type="checkbox" :value="parseInt(1)">
                <label class="form-check-label" for="active">Active</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="useCustomers.state.checkedCustomers" class="form-check-input" type="checkbox" :value="parseInt(0)">
                <label class="form-check-label" for="inactive">Inactive</label>
            </div>
        </div>


        <div
            v-show="useCustomers.state.form.status !== 'success'"
            class="spinner"
        >
            Loading customers...
        </div>


        <div
            v-if="useCustomers.state.form.status === 'success'"
        >

            <div class="table-responsive">
                <table class="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th scope="col">id</th>
                            <!-- <th scope="col">profession</th> -->
                            <th scope="col">civility</th>
                            <th scope="col">lastname</th>
                            <th scope="col">firstname</th>
                            <!-- <th scope="col">street</th> -->
                            <!-- <th scope="col">number</th> -->
                            <th scope="col">postcode</th>
                            <th scope="col">city</th>
                            <!-- <th scope="col">phone</th> -->
                            <!-- <th scope="col">mobile</th> -->
                            <th scope="col">email</th>
                            <th scope="col">active</th>
                            <th scope="col">Show</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="customer in useCustomers.state.selectedCustomers"
                            v-bind:key="customer"
                        >
                            <td>{{ customer.id }}</td>
                            <!-- <td>{{ customer.profession }}</td> -->
                            <td>{{ customer.civility }}</td>
                            <td>{{ customer.lastname }}</td>
                            <td>{{ customer.firstname }}</td>
                            <!-- <td>{{ customer.street }}</td> -->
                            <!-- <td>{{ customer.number }}</td> -->
                            <td>{{ customer.postcode }}</td>
                            <td>{{ customer.city }}</td>
                            <!-- <td>{{ customer.phone }}</td> -->
                            <!-- <td>{{ customer.mobile }}</td> -->
                            <td>
                                <a v-bind:href="'mailto:' + customer.email">
                                    {{ customer.email }}
                                </a>
                            </td>
                            <td>
                                <a
                                    v-if="customer.active == 1"
                                    class="icon-edit"
                                    href="#"
                                    @click.prevent="toggleActive(customer)"
                                >
                                    <i class="bi bi-check-square"></i>
                                </a>

                                <a
                                    v-else
                                    class="icon-edit"
                                    href="#"
                                    @click.prevent="toggleActive(customer)"
                                >
                                    <i class="bi bi-x-square"></i>
                                </a>
                            </td>

                            <td>
                                <a
                                    class="icon-edit"
                                    href="#"

                                    @click.prevent="showResource(customer)"
                                >
                                    <i class="bi bi-eye"></i>
                                </a>
                            </td>
                            <td>
                                <a
                                    class="icon-edit"
                                    href="#"

                                    @click.prevent="editResource(customer)"
                                >
                                    <i class="bi bi-pencil-fill"></i>
                                </a>
                            </td>
                            <td>
                                <a
                                    class="icon-edit"
                                    href="#"
                                    @click.prevent="useCustomers.deleteResource(customer.id)"
                                >
                                    <i class="bi bi-trash"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>



        <MyModal
            v-bind:idProps="state.modal.show"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.header }}
            </template>

            <template v-slot:body>

                <table class="table">
                    <thead>
                        <th scope="col">Index</th>
                        <th scope="col">Item</th>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in useCustomers.state.form.body"
                            v-bind:key="item"
                        >
                            <td>{{ index }}</td>
                            <td>{{ item }}</td>
                        </tr>
                    </tbody>
                </table>

            </template>


        </MyModal>




        <MyModal
            v-bind:idProps="state.modal.edit"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.header }}
            </template>

            <template v-slot:body>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="profession"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.profession) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="profession"
                        v-model="useCustomers.state.form.body.profession"
                    >
                    <label for="profession">Profession</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.profession)" class="text-info text-sm">Please fill up with your profession</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.profession"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <select
                        class="form-select rounded-4"
                        id="civility"
                        v-model="useCustomers.state.form.body.civility"
                        @keyup.enter="saveResource()"
                    >
                        <option value="">Select civility...</option>
                        <option v-for="option in useCustomers.state.form.options.civilities" :value="option.value" v-bind:key="option.value">
                            {{ option.text }}
                        </option>

                    </select>
                    <label for="civility">Civility</label>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="lastname"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.lastname) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="lastname"
                        v-model="useCustomers.state.form.body.lastname"
                    >
                    <label for="lastname">Lastname</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.lastname)" class="text-info text-sm">Please fill up with your lastname</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.lastname"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="firstname"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.firstname) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="firstname"
                        v-model="useCustomers.state.form.body.firstname"
                    >
                    <label for="firstname">Firstname</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.firstname)" class="text-info text-sm">Please fill up with your firstname</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.firstname"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="street"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.street) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="street"
                        v-model="useCustomers.state.form.body.street"
                    >
                    <label for="street">Street</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.street)" class="text-info text-sm">Please fill up with your street</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.street"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="number"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.number) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="number"
                        v-model="useCustomers.state.form.body.number"
                    >
                    <label for="number">Number</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.number)" class="text-info text-sm">Please fill up with your number</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.number"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="number"
                        class="form-control rounded-4"
                        id="postcode"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.postcode) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="postcode"
                        v-model="useCustomers.state.form.body.postcode"
                    >
                    <label for="postcode">Postcode</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.postcode)" class="text-info text-sm">Please fill up with your postcode</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.postcode"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="city"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.city) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="city"
                        v-model="useCustomers.state.form.body.city"
                    >
                    <label for="city">City</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.city)" class="text-info text-sm">Please fill up with your city</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.city"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="phone"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.phone) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="phone"
                        v-model="useCustomers.state.form.body.phone"
                    >
                    <label for="phone">Phone</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.phone)" class="text-info text-sm">Please fill up with your phone</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.phone"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="mobile"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.mobile) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="mobile"
                        v-model="useCustomers.state.form.body.mobile"
                    >
                    <label for="mobile">Mobile</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.mobile)" class="text-info text-sm">Please fill up with your mobile</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.mobile"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="email"
                        class="form-control rounded-4"
                        id="emailInput"
                        v-bind:class="formUtilsComp.isEmailValid(useCustomers.state.form.body.email) ? 'is-valid' : 'is-invalid'"
                        @keyup.enter="saveResource()"
                        placeholder="name@example.com"
                        v-model="useCustomers.state.form.body.email"
                    >
                    <label for="emailInput">Email address</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="!formUtilsComp.isEmailValid(useCustomers.state.form.body.email)" class="text-info text-sm">Please enter a valid email address</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="formUtilsComp.state.form.errors.email"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <select
                        class="form-select rounded-4"
                        id="active"
                        v-model="useCustomers.state.form.body.active"
                        @keyup.enter="saveResource()"
                    >
                        <option value="">Select active...</option>
                        <option v-for="option in useCustomers.state.form.options.active" :value="option.value" v-bind:key="option.value">
                            {{ option.text }}
                        </option>

                    </select>
                    <label for="active">Active</label>
                </div>


                <button
                    @click="saveResource()"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                >Save</button>


            </template>

        <!--
            <template v-slot:footer>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"><strong>Submit</strong></button>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0" data-bs-dismiss="modal">Cancel</button>
            </template>
        -->
        </MyModal>





    </div>


</template>

<script>
// import Vue.js framework functions
import { onMounted } from 'vue'
import { reactive } from 'vue'

// import external functionalities
import { Modal } from 'bootstrap';

// import application's components
import MyModal from '@/components/MyModal.vue'

// import composables files
import useObjectsUtils from '@/composables/useObjectsUtils.js'
import useFormUtilsComp from '@/composables/useFormUtilsComp.js'
import useCustomersComp from '@/composables/useCustomersComp.js'

// import informations and errors messages components
import ValidationErrors from '@/components/messages/validationErrors.vue'

export default {

    name: 'customers',

    components: {
        MyModal,
        ValidationErrors,
    },

    setup() {

        const objUtils = useObjectsUtils()
        const useCustomers = useCustomersComp()
        const formUtilsComp = useFormUtilsComp()

        const state = reactive({
            modal: {
                edit: 'editCustomerModal',
                show: 'showCustomerModal',
                header: '',
                action: '',
                componentEdit: null,
                componentShow: null
            },
        });

        onMounted(() => {
            useCustomers.getCustomers()
            state.modal.componentEdit = new Modal(document.getElementById(state.modal.edit))
            state.modal.componentShow = new Modal(document.getElementById(state.modal.show))
        });


        // Erase resource data before insertion in form
        function createResource() {

            // Define modal attributes
            state.modal.header = 'Create new customer'
            state.modal.action = 'create'

            // Open Modal component
            state.modal.componentEdit.toggle()

            // Erase error messages
            formUtilsComp.useResetErrors()

            // Flush user's attributes
            useCustomers.flushAttributes()
        }

        // Clone resource to update before insertion in form
        function editResource(resource) {

            // Define modal attributes
            state.modal.header = 'Edit customer'
            state.modal.action = 'edit'

            // Open Modal component
            state.modal.componentEdit.toggle()

            // Erase error messages
            formUtilsComp.useResetErrors()

            // Clone user's attributes
            useCustomers.state.form.body = objUtils.cloneObject(resource)
        }


        // Clone resource to update before insertion in form
        function showResource(resource) {

            // Define modal attributes
            state.modal.header = 'Show customer'
            state.modal.action = 'show'

            // Open Modal component
            state.modal.componentShow.toggle()

            // Erase error messages
            formUtilsComp.useResetErrors()

            // Clone user's attributes
            useCustomers.state.form.body = objUtils.cloneObject(resource)
        }




        // Save new created or updated resource
        function saveResource() {

            // Erase error messages
            formUtilsComp.useResetErrors()

            if ( useCustomers.state.form.body.id == null ) {

                useCustomers.storeResource(useCustomers.state.form.body)
                .then((res) => {
                    console.log(res)
                    // Close Modal component
                    state.modal.componentEdit.toggle()
                })
                .catch((error) => {
                    formUtilsComp.useSetErrors(error.response.data)
                })
            } else {
                useCustomers.updateResource(useCustomers.state.form.body)
                .then((res) => {
                    console.log(res)
                    // Close Modal component
                    state.modal.componentEdit.toggle()
                })
                .catch((error) => {
                    formUtilsComp.useSetErrors(error.response.data)
                })
            }
        }

        function toggleActive(resource) {

            useCustomers.state.form.status = 'toggeling'

            //let id = resource.id

            if (resource.active == 1) {
                resource.active = 0
            } else {
                resource.active = 1
            }

            useCustomers.updateResource(resource)
            .then((res) => {
                console.log(res)
                // Close Modal component
                //state.modal.componentEdit.toggle()
            })
            .catch((error) => {
                formUtilsComp.useSetErrors(error.response.data)
            })

        }


        return {
            state,
            useCustomers,
            formUtilsComp,
            createResource,
            editResource,
            showResource,
            saveResource,
            toggleActive
        }

    },
}

</script>

<style>
    @import '../../assets/css/logo.css';
</style>
