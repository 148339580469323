/*
|--------------------------------------------------------------------------
| Users management features and utilities composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different views or components for the Users management
| of the application.
*/

// Import Vue.js Framework functions
import { reactive } from 'vue'
import { computed } from 'vue'

// Import librairies
//import axios from 'axios'

// import own utility function library
import useCrudComp from './useCrudComp.js'
import useErrorsComp from '@/composables/useErrorsComp.js'

export default function useCustomersComp() {

    // define variable with the needed utils functions
    const useCrud = useCrudComp()
    const useErrors = useErrorsComp()

    // define the reactive variables
    const state = reactive({

        form: {
            body: {
                id: null,
                profession: '',
                civility: '',
                lastname: '',
                firstname: '',
                street: '',
                number: '',
                postcode: '',
                city: '',
                phone: '',
                mobile: '',
                email: '',
                active: '',
            },
            options: {
                civilities: [
                    { text: 'Madame', value: 'Madame' },
                    { text: 'Monsieur', value: 'Monsieur' },
                ],
                active: [
                    { text: '0 : Non', value: 0 },
                    { text: '1 : Oui', value: 1 },
                ],
            },
            status: null,
            resources: null,
            loading: true,

            response: {
                message: null,
                alert: null,
            }
        },

        checkedCustomers: [1],

        selectedCustomers : computed(() => {

            let length = state.checkedCustomers.length
            var selectedCustomersToShow = []

            for (var i = 0; i < length; i++) {
                selectedCustomersToShow[i] = state.checkedCustomers[i]
            }

            state.form.resources.sort( compare )

            return state.form.resources.filter(function(customer) {
                return selectedCustomersToShow.includes(customer.active)
            })

        })

    })

    // Compare 2 data by specific attributes
    function compare( a, b ) {

        if ( a.lastname.toLowerCase() < b.lastname.toLowerCase() ) {
            return -1;
        }
        if ( a.lastname.toLowerCase() > b.lastname.toLowerCase() ) {
            return 1;
        }
        return 0;
    }


    // Flush all user's attributes
    function flushAttributes() {
        state.form.body.id = null
        state.form.body.profession = ''
        state.form.body.civility = ''
        state.form.body.lastname = ''
        state.form.body.firstname = ''
        state.form.body.street = ''
        state.form.body.number = ''
        state.form.body.postcode = ''
        state.form.body.city = ''
        state.form.body.phone = ''
        state.form.body.mobile = ''
        state.form.body.email = ''
        state.form.body.active = ''
    }

    // Flush all user's attributes
    function initAttributes() {
        state.form.body.active = 1
    }

    // Get resources from backend
    function getCustomers() {

        state.form.loading = true

        return useCrud.getAllResources({
            url: 'customers'
        })
        .then((res) => {
            state.form.resources = res.data.data
            state.form.loading = false
            state.form.status = 'success'
        });
    }


    // Store new created resource to backend
    function searchResources(data) {

        useErrors.useResetErrors()
        state.form.status = 'saving'

        return useCrud.searchResources({
            url: 'customers/search/' + data,
            resource: data
        })
        .then((res) => {
            state.form.status = 'success'
            return res.data.resource
        })
    }



    // Store new created resource to backend
/*
    function storeResource(resource) {

        state.form.status = 'saving'

        return new Promise((resolve, reject) => {

            axios({
                method: 'post',
                url: 'customers/',
                data: resource
            })
            .then((res) => {
                state.form.resources.push(res.data.resource)

                state.form.status = 'success'

                resolve(res)
            })
            .catch((err) => {
                state.form.status = 'error'
                reject(err)
            })

        }) // new Promise
    }
*/

    // Store new created resource to backend
    function storeResource() {

        useErrors.useResetErrors()
        state.form.status = 'saving'

        return useCrud.storeResource({
            url: 'customers',
            resource: state.form.body
        })
        .then((res) => {
            state.form.resources.push(res.data.resource)

            state.form.status = 'success'
        })
    }

    // Update modified resource in backend
/*
    function updateResource(resource) {

        state.form.status = 'updating'

        //let id = state.form.body.id

        return new Promise((resolve, reject) => {

            axios({
                method: 'put',
                url: 'customers/' + resource.id,
                data: resource
            })
            .then((res) => {

                var index = state.form.resources.findIndex(x => x.id === res.data.resource.id)

                // if resource not existing in array
                if (index < 0) {
                    // add resource to array
                    state.form.resources.push(res.resource)
                } else {
                    // update resource in array
                    state.form.resources.splice(index, 1, res.data.resource)
                }

                state.form.status = 'success'

                resolve(res)
            })
            .catch((err) => {
                state.form.status = 'error'
                reject(err)
            })

        }) // new Promise
    }
*/

    // Update modified resource in backend
    function updateResource() {

        useErrors.useResetErrors()
        state.form.status = 'updating'

        let id = state.form.body.id

        return useCrud.updateResource({
            url: 'customers/' + id,
            resource: state.form.body
        })
        .then((res) => {
            var index = state.form.resources.findIndex(x => x.id === res.data.resource.id)

            // if resource not existing in array
            if (index < 0) {
                // add resource to array
                state.form.resources.push(res.data.resource)
            } else {
                // update resource in array
                state.form.resources.splice(index, 1, res.data.resource)
            }

            state.form.status = 'success'
            state.form.response.message = res.data.message
        })
    }

    // Delete resource in backend
/*
    function deleteResource(id) {

        state.form.status = 'deleting'

        return new Promise((resolve, reject) => {

            axios({
                method: 'delete',
                url: 'customers/' + id,
                data: id
            })
            .then((res) => {
                var index = state.form.resources.findIndex(x => x.id === id)
                state.form.resources.splice(index, 1)

                state.form.status = 'success'

                resolve(res)
            })
            .catch((err) => {
                state.form.status = 'error'
                reject(err)
            })
        }) // new Promise
    }
*/

    // Delete resource in backend
    function deleteResource(id) {

        return useCrud.deleteResource({
            url: 'customers/' + id,
            resource: id
        })
        .then(() => {
            var index = state.form.resources.findIndex(x => x.id === id)
            state.form.resources.splice(index, 1)

            state.form.status = 'success'
        })

    }


    return {
        state,
        useErrors,
        flushAttributes,
        initAttributes,
        getCustomers,
        searchResources,
        storeResource,
        updateResource,
        deleteResource
    }

}
